const STORE_FRONT_VIEW_MODES = [
  {
    key: "grid",
    svg:
      // prettier-ignore
      <svg width="100%" viewBox="0 0 19 19" fill="none">
        <path d="M5.43 1.5H4.005C2.3625 1.5 1.5 2.3625 1.5 3.9975V5.4225C1.5 7.0575 2.3625 7.92 3.9975 7.92H5.4225C7.0575 7.92 7.92 7.0575 7.92 5.4225V3.9975C7.9275 2.3625 7.065 1.5 5.43 1.5Z" fill="currentColor"/>
        <path d="M14.0026 1.5H12.5776C10.9426 1.5 10.0801 2.3625 10.0801 3.9975V5.4225C10.0801 7.0575 10.9426 7.92 12.5776 7.92H14.0026C15.6376 7.92 16.5001 7.0575 16.5001 5.4225V3.9975C16.5001 2.3625 15.6376 1.5 14.0026 1.5Z" fill="currentColor"/>
        <path d="M14.0026 10.0723H12.5776C10.9426 10.0723 10.0801 10.9348 10.0801 12.5698V13.9948C10.0801 15.6298 10.9426 16.4923 12.5776 16.4923H14.0026C15.6376 16.4923 16.5001 15.6298 16.5001 13.9948V12.5698C16.5001 10.9348 15.6376 10.0723 14.0026 10.0723Z" fill="currentColor"/>
        <path d="M5.43 10.0723H4.005C2.3625 10.0723 1.5 10.9348 1.5 12.5698V13.9948C1.5 15.6373 2.3625 16.4998 3.9975 16.4998H5.4225C7.0575 16.4998 7.92 15.6373 7.92 14.0023V12.5773C7.9275 10.9348 7.065 10.0723 5.43 10.0723Z" fill="currentColor"/>
      </svg>,
  },
  {
    key: "card",
    svg:
      //prettier-ignore
      <svg width="90%" viewBox="0 0 20 20" fill="none">
        <path d="M10.1625 15.1199C9.96751 15.2024 9.75751 15.2774 9.53251 15.3524L8.34751 15.7424C5.37001 16.7024 3.80251 15.8999 2.83501 12.9224L1.87501 9.95994C0.915012 6.98244 1.71001 5.40744 4.68751 4.44744L5.84251 4.06494C5.70001 4.42494 5.58001 4.83744 5.46751 5.30244L4.73251 8.44494C3.90751 11.9774 5.11501 13.9274 8.64751 14.7674L9.90751 15.0674C9.99001 15.0899 10.08 15.1049 10.1625 15.1199Z" fill="currentColor"/>
        <path d="M12.8774 2.40751L11.6249 2.11501C9.11989 1.52251 7.62739 2.01001 6.74989 3.82501C6.52489 4.28251 6.34489 4.83751 6.19489 5.47501L5.45989 8.61751C4.72489 11.7525 5.69239 13.2975 8.81989 14.04L10.0799 14.34C10.5149 14.445 10.9199 14.5125 11.2949 14.5425C13.6349 14.7675 14.8799 13.6725 15.5099 10.965L16.2449 7.83001C16.9799 4.69501 16.0199 3.14251 12.8774 2.40751Z" fill="currentColor"/>
      </svg>,
  },
  {
    key: "horizontal",
    svg:
      // prettier-ignore
      <svg width="90%" viewBox="0 0 23 20" fill="none">
        <rect y="0.000244141" width="19.3818" height="8.03637" rx="2.36364" fill="currentColor"/>
        <rect x="3" y="2.57251" width="3" height="3" rx="1" fill="white"/>
        <rect x="7.19995" y="3.3634" width="8.5" height="1.41818" rx="0.709091" fill="white"/>
        <rect y="9.92749" width="19.3818" height="8.03637" rx="2.36364" fill="currentColor"/>
        <rect x="3" y="12.4998" width="3" height="3" rx="1" fill="white"/>
        <rect x="7.19995" y="13.2906" width="8.5" height="1.41818" rx="0.709091" fill="white"/>
      </svg>,
  },
];

const VIEW_MODES = {
  grid: "Grid View",
  card: "Swiping Card",
  horizontal: "Horizontal View",
};

const ORDER_STATUSES: { [key: string]: { label: string; status: string } } = {
  PENDING: { label: "Your order was received", status: "Pending" },
  PROCESSING: { label: "Your order started processing", status: "Processing" },
  PAYMENT_RECEIVED: { label: "Your payment was received", status: "Payment received" },
  FULFILLED: { label: "Your order was fulfilled", status: "Fulfilled" },
  CANCELLED: { label: "Your order was cancelled", status: "Cancelled" },
};

const ORDERS_VIEW_MODES = [
  {
    key: "grid",
    svg: (
      <svg width="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6667 5H5V16.6667H16.6667V5Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.9997 5H23.333V16.6667H34.9997V5Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.9997 23.3333H23.333V34.9999H34.9997V23.3333Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 23.3333H5V34.9999H16.6667V23.3333Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    key: "table",
    svg: (
      <svg width="100%" viewBox="0 0 40 40" fill="none">
        <path d="M5 20H35" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 10H35" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 30H35" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  },
];

const WHATSAPP_LINK = "https://api.whatsapp.com/send/?phone=2349042550548";
const HELP_DESK_LINK =
  "https://catlog-help-center.notion.site/49b13cc6f3904725b8f29aa337f7d73b?v=feb710b3c73245b185f88a4c88a9551a";

export { STORE_FRONT_VIEW_MODES, VIEW_MODES, ORDER_STATUSES, ORDERS_VIEW_MODES, WHATSAPP_LINK, HELP_DESK_LINK };
